.rdrCalendarWrapper {
  width: 100%;
}

.rdrDateRangePickerWrapper {
  width: 100%;
}

.rdrMonthAndYearWrapper {
  padding: 0;
}

.rdrMonthAndYearPickers select {
  border: 1px solid #bcbec0;
  background-image: url(../images/icon_arrow_down.svg);
  background-position-x: 95%;
  background-size: 18px;
}

.rdrMonthPicker select {
  height: 28px;
  padding: 0 30px 0 10px;
}

.rdrYearPicker select {
  height: 28px;
  padding: 0 30px 0 10px;
}

.rdrMonthAndYearPickers select::-webkit-scrollbar {
  display: none;
}

.rdrMonthAndYearPickers select:hover {
  background-color: white;
}

#no_dropdown_picker .rdrMonthAndYearPickers {
  display: none;
}

#no_dropdown_picker.mobile .rdrMonthAndYearWrapper {
  display: none;
}

#no_dropdown_picker.mobile .rdrWeekDays {
  background: #fdfdfd;
}

#no_dropdown_picker.mobile .rdrDay {
  background: #fdfdfd;
}

#no_dropdown_picker.mobile .rdrMonth {
  background: #fdfdfd;
}

.rdrDayPassive {
  opacity: 0;
}

.rdrDay {
  color: #4c4c4c;
}

.rdrDayNumber {
  font-weight: 500;
  font-size: 16px;
}

.rdrMonthName {
  font-size: 16px;
  color: #4c4c4c;
}

.rdrWeekDay {
  font-size: 14px;
  color: black;
}

.rdrDayToday .rdrDayNumber span::after {
  display: none;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
  background: white;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayInPreview,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayNumber::after {
  display: none;
}

.rdrDayDisabled {
  background-color: white;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #c6c2c1;
  font-weight: 400;
}

.rdrWeekDays {
  border-bottom: 1px #ebedec solid;
  background: white;
}

.desktop .rdrMonth {
  height: 272px;
}

.desktop .rdrMonths {
  padding: 0 40px;
}

.desktop .rdrMonthAndYearWrapper {
  height: 0;
  padding: 0 2px;
  position: relative;
  top: 171px;
}

.desktop .rdrSelected {
  border-radius: 4px;
  background-color: #4c4c4c;
  color: white;
  height: 36px;
  width: 36px;
}

.desktop .rdrDays {
  margin-top: 10px;
}

.desktop .rdrDay {
  height: 39px;
}

.desktop .rdrDayNumber {
  height: 36px;
  width: 39px;
}

.desktop .rdrNextPrevButton i {
  display: none;
}

.desktop .rdrNextPrevButton {
  width: 7.66px;
  height: 13.31px;
  background: url(../images/icon_arrow_calendar.svg) no-repeat;
  border: none;
}

.desktop .rdrPprevButton {
  transform: rotateY(180deg);
}

.desktop .rdrWeekDay {
  color: #4c4c4c;
}

.mobile .rdrNextPrevButton {
  display: none;
}

.mobile .rdrMonth {
  width: 100%;
  background: white;
}

.mobile .rdrWeekDays {
  background: white;
}

.mobile .rdrSelected {
  border-radius: 4px;
  background-color: #4c4c4c;
  color: white;
  width: 38px;
  height: 38px;
  margin: auto;
  top: 10px;
}

.mobile .rdrMonthName {
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  color: #4c4c4c;
}

.mobile .rdrDayNumber {
  top: 10px;
}

.mobile .rdrDay {
  height: 49.83px;
  background: white;
}

.mobile .rdrDays {
  margin-bottom: 20px;
}

.rdrInfiniteMonths {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.rdrInfiniteMonths::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
