.next-enter,
.next-appear {
  opacity: 1;
  transform: translateX(100%);
}

.next-enter-active,
.next-appear-active {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 500ms;
}

.next-exit {
  opacity: 1;
  transform: translateX(-100%);
}

.next-exit-active {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 500ms;
}